<template>
  <v-menu ref="menuRef" v-model="menu" :close-on-content-click="false" :return-value.sync="time" transition="scale-transition" offset-y min-width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field v-model="timeText" v-bind="$attrs" :label="label" readonly v-on="on" :outlined="outlined">
        <template v-if="clearButton && time !== ''" v-slot:append-outer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click="time = ''">fal fa-times-circle</v-icon>
            </template>
            Clear Entry
          </v-tooltip>
        </template>
        <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
          <slot :name="scopedSlotName" v-bind="slotData"></slot>
        </template>
        <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
          <slot :name="slotName"></slot>
        </template>
      </v-text-field>
    </template>
    <v-time-picker v-model="time" ref="picker" :no-title="noTitle" scrollable>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
      <v-btn text color="primary" @click="$refs.menuRef.save(time)">OK</v-btn>
    </v-time-picker>
  </v-menu>
</template>
<script>
import { computed, ref, watch } from '@vue/composition-api'
export default {
  props: {
    label: {
      type: String,
      default: 'Time'
    },
    outlined: {
      type: Boolean,
      default: true
    },
    noTitle: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: () => {
        let dt = new Date()
        return dt.toLocaleTimeString()
      }
    },
    clearButton: {
      type: Boolean,
      default: () => false
    }
  },
  setup (props, { emit }) {
    const menu = ref(false)
    const menuRef = ref(null)
    const time = ref('')

    watch(() => props.value, (val) => {
      if (val != null && time.value !== val) time.value = val
    })
    watch(time, (val) => {
      let regex = new RegExp('[0-2][0-9]:[0-5][0-9]')
      if (props.value !== val && regex.test(val)) {
        emit('input', val)
        emit('change', val)
      }
    })
    const timeText = computed(() => {
      let [hour, min] = time.value.split(':')
      let ampm = 'AM'
      hour = parseInt(hour)
      if (hour >= 12) {
        ampm = 'PM'
        if (hour > 12) hour -= 12
      }
      return hour + ':' + min + ' ' + ampm
    })
    return {
      menu,
      menuRef,
      time,
      timeText
    }
  }
}
</script>
